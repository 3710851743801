.ant-menu-horizontal .ant-menu-item a {
    text-decoration: none;
}

/* Optionally, change link text color when hovered */
.ant-menu-horizontal .ant-menu-item a:hover {
    color: #0078d4;
    /* Change to your desired hover color */
}

.cneter {
    justify-content: center;
}

.left-item {
    float: left;
    /* Make the left item float to the left */
    clear: left;
    /* Clear any previous floats on the left */
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    border: #0078d4;
    /* Add your desired background color */
    z-index: 999;
    /* Add a z-index to control the stacking order */
}

.logo {
    display: block;
    /* Show the logo by default */
}

@media screen and (max-width: 767px) {
    .logo {
        display: none;
        /* Hide the logo on smaller screens */
    }
}